import React from 'react'
import styled from 'styled-components'
import Container from 'components/shared/Container'
import Icon from 'components/shared/Icon'
import dubai from 'assets/icons/dubai.png'
import prague from 'assets/icons/prague.png'
import warsaw from 'assets/icons/warsaw.png'
import brno from 'assets/icons/brno.png'
import facebook from 'assets/icons/facebook.svg'
import linkedin from 'assets/icons/linkedin.svg'

const StyledFooter = styled.footer`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark};
`

const Cities = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3rem 0;

  ${({ theme }) => theme.media.sm.min} {
    padding: min(5vw, 6rem) 0;
  }
`

const City = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  max-width: 80px;

  ${({ theme }) => theme.media.sm.min} {
    margin: 0 min(2vw, 2rem);
  }
`

const CityIcon = styled.img`
  width: 85%;
  margin-bottom: 0.5rem;
`

const CityLabel = styled.span`
  font-size: clamp(1rem, 2.5vw, 1.3rem);
  color: ${({ theme }) => theme.colors.white100};
`

const BottomBar = styled.div`
  padding: 1rem 0;
  border-top: 1px solid ${({ theme }) => theme.colors.white200};
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: center;
  }
`

const Copyright = styled.p`
  color: ${({ theme }) => theme.colors.white200};
  ${({ theme }) => theme.media.lg.min} {
    margin-right: auto;
  }
`

const SocialMediaLink = styled.a``

const StyledIcon = styled(Icon)`
  display: none;
  ${({ theme }) => theme.media.lg.min} {
    display: block;
    margin-left: 1rem;
  }
`

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <Cities>
          <City>
            <CityIcon src={dubai} alt="dubai" />
            <CityLabel>Dubai</CityLabel>
          </City>
          <City>
            <CityIcon src={prague} alt="prague" />
            <CityLabel>Prague</CityLabel>
          </City>
          <City>
            <CityIcon src={warsaw} alt="warsaw" />
            <CityLabel>Warsaw</CityLabel>
          </City>
          <City>
            <CityIcon src={brno} alt="brno" />
            <CityLabel>Brno</CityLabel>
          </City>
        </Cities>
        <BottomBar>
          <Copyright>
            Copyright &copy; Business Factory General terms and conditions | EU
            funding
          </Copyright>
          <SocialMediaLink href="https://www.facebook.com/businessfactorycz">
            <StyledIcon src={facebook} size={12} />
          </SocialMediaLink>
          <SocialMediaLink href="https://www.linkedin.com/company/business-factory-s-r-o-/">
            <StyledIcon src={linkedin} size={12} />
          </SocialMediaLink>
        </BottomBar>
      </Container>
    </StyledFooter>
  )
}

export default Footer
