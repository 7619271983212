/* eslint-disable */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { ImageDataLike } from 'gatsby-plugin-image'
import Container from 'components/shared/Container'
import Modal from './Modal'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import LazyImage from 'components/shared/LazyImage'
import circles from 'assets/icons/circles.svg'

type Props = {
  title: string
  img: ImageDataLike
}

const StyledSection = styled.section`
  position: relative;
`

const StyledContainer = styled(Container)`
  width: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Heading = styled.h3`
  margin-bottom: 5rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: clamp(1.6rem, 4vw, 2rem);
  ${({ theme }) => theme.media.md.min} {
    margin-bottom: min(8vw, 8rem);
    font-size: clamp(2.2rem, 2.3vw, 3.2rem);
  }
`

const BackgroundWrapper = styled.div`
  position: relative;
  min-height: 310px;
  & > :last-child {
    min-height: 310px;
  }
`

const CirclesWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 250px;
  height: 250px;
  transform: translate(-50%, -50%) rotate(180deg);
  z-index: 2;

  ${({ theme }) => theme.media.md.min} {
    width: clamp(250px, 27vw, 450px);
    height: clamp(250px, 27vw, 450px);
  }
`

const StyledButton = styled(Button)`
  position: relative;
  width: 265px;
  z-index: 5;
  margin: 0;
`

const BlurOverlay = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
      cursor: pointer;
    `}
`

const Appointment: React.FC<Props> = ({ title, img }) => {
  const [modalVisible, setModalVisible] = useState(false)

  const closeModal = () => {
    setModalVisible(false)
  }

  return (
    <StyledSection>
      <BlurOverlay visible={modalVisible} />
      <BackgroundWrapper>
        <CirclesWrapper>
          <Icon src={circles} />
        </CirclesWrapper>
        <StyledContainer>
          <Heading dangerouslySetInnerHTML={{ __html: title }} />
          <StyledButton onClick={() => setModalVisible(true)} contactForm>
            Zamawiam
          </StyledButton>
        </StyledContainer>
        <LazyImage src={img} alt="legacy-tower-warsaw" />
      </BackgroundWrapper>
      <Modal visible={modalVisible} close={closeModal} />
    </StyledSection>
  )
}

export default Appointment
