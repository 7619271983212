import React from 'react'
import styled, { css } from 'styled-components'
import { FieldProps } from 'formik'
import Icon from 'components/shared/Icon'

type Props = {
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  textarea?: boolean
  icon: string
  contactForm?: boolean
  websiteInput?: boolean
}

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  margin-top: 3rem;
`

const InputWrapper = styled.div<{ error: boolean; contactForm?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};

  ${({ contactForm }) =>
    contactForm &&
    css`
      border-bottom: 1px solid #53677c};
    `}

  ${({ error }) =>
    error &&
    css`
      border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    `}
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  margin: 3px 1.25rem 0 0.75rem;
`

const Input = styled.input<{ website?: boolean }>`
  width: 100%;
  padding-right: 1rem;
  font-size: 1.4rem;
  font-weight: 300;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.white};

  :-webkit-autofill,
  :-webkit-autofill:focus,
  :-webkit-autofill:hover {
    background-color: #3a4f64;
    box-shadow: 0 0 0px 1000px #3a4f64 inset;
    color: ${({ theme }) => theme.colors.white};
    -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
    caret-color: ${({ theme }) => theme.colors.white};
  }

  ::placeholder {
    color: ${({ theme, website }) =>
      website ? '#67809b' : theme.colors.white100};
  }
`

const ErrorMessage = styled.p`
  width: 100%;
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
`

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  padding-right: 1rem;

  ${({ theme }) => theme.media.xl.min} {
    height: 90px;
  }
`

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  required,
  placeholder,
  disabled,
  readonly,
  icon,
  textarea,
  contactForm,
  websiteInput,
}) => {
  const errorText = errors[name] && touched[name] && errors[name]

  return (
    <InputContainer>
      {!!errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      <InputWrapper error={!!errorText} contactForm={contactForm}>
        <IconWrapper>
          <Icon src={icon} size={18} />
        </IconWrapper>

        {textarea ? (
          <Textarea
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            readOnly={!!readonly}
            required={required}
          />
        ) : (
          <Input
            id={name}
            name={name}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            readOnly={!!readonly}
            required={required}
            website={websiteInput}
          />
        )}
      </InputWrapper>
    </InputContainer>
  )
}

export default FormInput
