/* eslint-disable */
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/shared/Icon'
import Heading from 'components/shared/Heading'
import Container from 'components/shared/Container'
import scroll from 'assets/icons/scroll.svg'
import gridIcon1 from 'assets/icons/skalowanie-sprzedazy.svg'
import gridIcon2 from 'assets/icons/dzialania-sprzedazowe.svg'
import gridIcon3 from 'assets/icons/dzialania-performance.svg'
import gridIcon4 from 'assets/icons/social-media-management.svg'
import gridIcon5 from 'assets/icons/kreacja.svg'
import { ServiceObj } from 'types/data'
import scrollToSection from 'utils/scrollToSection'

type Props = {
  title: string
  subtitle: string
  description: string
  services: ServiceObj[]
}

const StyledSection = styled.section`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border-top-left-radius: clamp(70px, 12vw, 300px);
  border-top-right-radius: clamp(70px, 12vw, 300px);
`

const StyledContainer = styled(Container)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: min(15vw, 10rem) 0;
`

const ScrollIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  z-index: 2;
  cursor: pointer;
  ${({ theme }) => theme.media.lg.min} {
    width: 45px;
  }
`

const ScrollAnchor = styled.span`
  position: absolute;
  top: 40px;
  left: 0;
`

const Subtitle = styled.h6`
  position: relative;
  margin-top: 1.5rem;
  font-size: clamp(1.2rem, 2vw, 1.8rem);
  text-transform: uppercase;
  text-align: center;

  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 5%;
    min-width: 35px;
    max-width: 65px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Description = styled.div`
  max-width: 485px;
  margin: 4.5rem auto 0;
  padding: 0 1rem;
  font-family: 'PoppinsLight';
  font-size: clamp(1.4rem, 2.2vw, 1.7rem);
  text-align: center;
  line-height: 1.75;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 850px;
  }
`

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 6rem;
`

const Tile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 235px;
  min-height: 220px;
  padding: 6rem 2.5rem 2.5rem;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  margin-top: 5rem;
  ${({ theme }) => theme.media.xl.min} {
    width: 255px;
  }

  ${({ theme }) => theme.media.xxxl.min} {
    width: 270px;
    min-height: 235px;
  }
`

const TilesRow = styled.div<{ second?: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    & > div {
      margin: 5rem min(1.75vw, 4rem) 0;
    }
  }

  ${({ second }) =>
    second &&
    css`
      & > div {
        background-color: ${({ theme }) => theme.colors.white};
        border: 2px solid #f1f2f4;
        color: ${({ theme }) => theme.colors.primary};
      }

      ${({ theme }) => theme.media.lg.min} {
        & > div {
          margin: 5rem min(1vw, 2rem) 0;
        }
      }
    `}
`

const TileIconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-40%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

const TileTitle = styled.div`
  margin-bottom: 1.5rem;
  font-family: 'PoppinsLight';
  font-size: 1.6rem;
  text-align: center;
  ${({ theme }) => theme.media.xxxl.min} {
    font-size: 1.7rem;
  }
`

const TileDescription = styled.div`
  font-size: 1.25rem;
  text-align: center;
  ${({ theme }) => theme.media.xxxl.min} {
    font-size: 1.35rem;
  }
`

const getIcon = (icon: string) => {
  switch (icon.toLowerCase()) {
    case 'skalowanie sprzedaży':
      return gridIcon1
    case 'działania sprzedażowe':
      return gridIcon2
    case 'działania performance':
      return gridIcon3
    case 'social media management':
      return gridIcon4
    case 'kreacja':
      return gridIcon5
    default:
      return ''
  }
}

const MarketingPartner: React.FC<Props> = ({
  title,
  subtitle,
  description,
  services,
}) => {
  const tilesFirstRow = useMemo(() => services.slice(0, 2), [services])
  const tilesSecondRow = useMemo(() => services.slice(2, 5), [services])

  return (
    <StyledSection>
      <ScrollIconWrapper onClick={() => scrollToSection('#marketing-partner')}>
        <Icon src={scroll} />
      </ScrollIconWrapper>
      <StyledContainer>
        <ScrollAnchor id="marketing-partner" />
        <Heading dangerouslySetInnerHTML={{ __html: title }} />
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Tiles>
          <TilesRow>
            {tilesFirstRow.map(
              ({
                mainPageServicesServiceIcon,
                mainPageServicesServiceTitle,
                mainPageServicesServiceDescription,
              }) => (
                <Tile key={mainPageServicesServiceTitle}>
                  <TileIconWrapper>
                    <Icon
                      src={getIcon(mainPageServicesServiceIcon)}
                      size={30}
                    />
                  </TileIconWrapper>
                  <TileTitle>{mainPageServicesServiceTitle}</TileTitle>
                  <TileDescription>
                    {mainPageServicesServiceDescription}
                  </TileDescription>
                </Tile>
              )
            )}
          </TilesRow>
          <TilesRow second>
            {tilesSecondRow.map(
              ({
                mainPageServicesServiceIcon,
                mainPageServicesServiceTitle,
                mainPageServicesServiceDescription,
              }) => (
                <Tile key={mainPageServicesServiceTitle}>
                  <TileIconWrapper>
                    <Icon
                      src={getIcon(mainPageServicesServiceIcon)}
                      size={30}
                    />
                  </TileIconWrapper>
                  <TileTitle>{mainPageServicesServiceTitle}</TileTitle>
                  <TileDescription>
                    {mainPageServicesServiceDescription}
                  </TileDescription>
                </Tile>
              )
            )}
          </TilesRow>
        </Tiles>
      </StyledContainer>
    </StyledSection>
  )
}

export default MarketingPartner
