import React from 'react'
import { Field } from 'formik'
import Checkbox from './Checkbox'

type Props = {
  formName: string
}

const Terms: React.FC<Props> = ({ formName }) => {
  const formIdLabel = formName === 'contact' ? 'agree' : 'term'

  const TERMS = [
    {
      id: `${formIdLabel}1`,
      label:
        'Oświadczam, iż zapoznałem(-am) się z treścią <a href="https://cmspl.b.cz/wp-content/uploads/2022/03/klauzula.pdf" target="_blank" rel="noopener noreferrer" >klauzuli informacyjnej o zasadach dotyczących przetwarzania moich danych osobowych</a>, w tym z informacją o celu i sposobach przetwarzania danych osobowych oraz przysługujących mi uprawnieniach.',
    },
    {
      id: `${formIdLabel}2`,
      label:
        'Wyrażam zgodę na otrzymywanie informacji handlowych od <b>BUSINESS FACTORY spółka z ograniczoną odpowiedzialnością</b> z siedzibą Warszawie, nr KRS: 0000503115 drogą elektroniczną na wskazany w formularzu adres poczty elektronicznej.',
    },
    {
      id: `${formIdLabel}3`,
      label:
        'Wyrażam zgodę na marketing bezpośredni <b>BUSINESS FACTORY spółka z ograniczoną odpowiedzialnością</b> z siedzibą Warszawie nr KRS: 0000503115 przy użyciu telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących. ',
    },
  ]

  return (
    <>
      {TERMS.map((term) => (
        <Field
          key={term.id}
          type="checkbox"
          name={term.id}
          label={term.label}
          component={Checkbox}
        />
      ))}
    </>
  )
}

export default Terms
