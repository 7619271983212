/* eslint-disable */
import React, { useState } from 'react'
import { FieldProps } from 'formik'
import styled, { css } from 'styled-components'
import checkIconWhite from 'assets/icons/check-white.svg'
import Icon from './Icon'

type Props = {
  label: string
}

const SingleTerm = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1rem;
`

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Input = styled.input`
  align-self: flex-start;
  position: relative;
  width: 15px;
  height: 15px;
  width: 0;
  height: 0;
`

const Checkmark = styled.div<{ checked: boolean; error: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  border: 2px solid ${({ theme }) => theme.colors.white};
  margin-right: 1rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 3px;

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};
      border-color: ${({ theme }) => theme.colors.secondary};
    `}

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.secondary};
    `}
`

const LabelText = styled.p<{ expanded: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.colors.white100};

  a {
    font-family: 'PoppinsLight';
    color: ${({ theme }) => theme.colors.white};
  }

  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}

  ${({ theme }) => theme.media.xl.min} {
    font-size: 1.15rem;
  }
`

const Button = styled.button`
  align-self: flex-start;
  font-family: 'PoppinsMedium';
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`

const FormCheckbox: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  label,
}) => {
  const [expanded, setExpaned] = useState(false)

  const error = !!(touched[name] && errors[name])

  return (
    <SingleTerm>
      <Label htmlFor={name}>
        <Checkmark checked={value} error={error}>
          <Icon src={checkIconWhite} />
        </Checkmark>
        <Input
          id={name}
          name={name}
          type="checkbox"
          onChange={onChange}
          onBlur={onBlur}
          checked={value}
        />
        <LabelText
          dangerouslySetInnerHTML={{ __html: label }}
          expanded={expanded}
        />
      </Label>
      <Button type="button" onClick={() => setExpaned(!expanded)}>
        <p>{expanded ? 'Mniej' : 'Więcej'}</p>
      </Button>
    </SingleTerm>
  )
}

export default FormCheckbox
