import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  GatsbyImage,
  getImage,
  ImageDataLike,
  IGatsbyImageData,
  GatsbyImageProps,
} from 'gatsby-plugin-image'

type LazyImageProps = {
  src: ImageDataLike
  alt: string
  width?: number
  height?: number
  objectFit?: React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']

  fill?: boolean
  className?: string
}

const StyledGatsbyImage = styled<
  React.FC<GatsbyImageProps & { fill?: boolean }>
>(GatsbyImage).withConfig({
  shouldForwardProp: (prop) => prop !== 'fill',
})`
  ${({ fill }) =>
    fill &&
    css`
      object-fit: cover;
      object-position: '50% 50%';
      &,
      * {
        width: 100%;
        height: 100%;
      }
    `}
`

const LazyImage: React.FC<LazyImageProps> = ({
  src,
  alt,
  width,
  height,
  objectFit,
  objectPosition,
  className,
  fill,
}) => {
  let image = getImage(src) as IGatsbyImageData
  if (width && height) {
    image = { ...image, width, height }
  } else if (width) {
    image = { ...image, width, height: width * (image.width / image.height) }
  } else if (height) {
    image = { ...image, height, width: height * (image.width / image.height) }
  }

  return (
    <StyledGatsbyImage
      image={image}
      alt={alt ?? ''}
      objectFit={objectFit}
      objectPosition={objectPosition}
      className={className}
      fill={fill}
    />
  )
}

export default LazyImage
