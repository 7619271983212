/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import { ImageDataLike } from 'gatsby-plugin-image'
import Container from 'components/shared/Container'
import ContactForm from './ContactForm'
import Icon from 'components/shared/Icon'
import circles from 'assets/icons/circles.svg'

type Props = {
  title: string
}

const StyledSection = styled.section`
  position: relative;
  padding-bottom: 4rem;
  background-color: ${({ theme }) => theme.colors.primary};
  overflow: hidden;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 0;
  }
`

const StyledContainer = styled(Container)`
  ${({ theme }) => theme.media.lg.min} {
    align-self: center;
  }
`

const HeadingBackground = styled.div`
  width: 95%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 90px;
  border-bottom-right-radius: 90px;
  background-color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: 12.5rem;

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    height: clamp(620px, 45vw, 700px);
    margin-bottom: 0;
    border-top-right-radius: 300px;
    border-bottom-right-radius: 300px;
  }
`

const Heading = styled.h3`
  padding-left: 2rem;
  padding-right: 1rem;
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  font-size: clamp(1.6rem, 3vw, 2.4rem);
`

const CirclesWrapper = styled.div`
  position: absolute;
  top: -3%;
  right: -2%;
  transform: rotate(90deg);
  width: clamp(270px, 78vw, 750px);
  height: clamp(270px, 78vw, 750px);
  ${({ theme }) => theme.media.lg.min} {
    top: 50%;
    left: 50%;
    right: unset;
    transform: translate(-79%, -45%) rotate(90deg);
    width: clamp(550px, 50vw, 900px);
    height: clamp(550px, 50vw, 900px);
  }
`

const Contact: React.FC<Props> = ({ title }) => {
  return (
    <StyledSection>
      <CirclesWrapper>
        <Icon src={circles} />
      </CirclesWrapper>
      <HeadingBackground>
        <Heading dangerouslySetInnerHTML={{ __html: title }} />
      </HeadingBackground>
      <StyledContainer>
        <ContactForm />
      </StyledContainer>
    </StyledSection>
  )
}

export default Contact
