/* eslint-disable */
import React, { useState, useMemo } from 'react'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import styled, { css } from 'styled-components'
import { TechnologyObj } from 'types/data'
import Icon from 'components/shared/Icon'
import LazyLoad from 'react-lazyload'
import Collapsible from 'react-collapsible'
import circles from 'assets/images/circles.svg'
import arrowPrimaryLeft from 'assets/icons/arrow-primary-left.svg'
import arrowSecondaryLeft from 'assets/icons/arrow-secondary-left.svg'
import arrowSecondaryRight from 'assets/icons/arrow-secondary-right.svg'
import arrowPrimaryRight from 'assets/icons/arrow-primary-right.svg'

type Props = {
  title: string
  description: string
  technologies: TechnologyObj[]
}

const StyledSection = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  padding-bottom: 2.5rem;
`

const StyledHeading = styled(Heading)`
  position: relative;
  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    width: 5%;
    min-width: 35px;
    max-width: 65px;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

const Description = styled.div`
  max-width: 485px;
  margin: 4.5rem auto 0;
  padding: 0 1rem;
  font-family: 'PoppinsLight';
  font-size: clamp(1.4rem, 2.2vw, 1.7rem);
  text-align: center;
  line-height: 1.75;
  ${({ theme }) => theme.media.lg.min} {
    max-width: 850px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.media.lg.min} {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`

const ImageSection = styled.div`
  position: relative;
  margin: 3rem 0;
  width: 100%;
`

const CirclesIconWrapper = styled.div`
  width: 90%;
  transform: translateX(-30%);
`

const GifWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -52.5%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  max-width: 85%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 37px;
  border: 5px solid rgba(245, 245, 245, 0.3);

  & > img {
    max-width: 100%;
    border-radius: 37px;
  }
`

const TechnologiesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    margin-top: 4rem;
  }
`

const Buttons = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;
  margin-bottom: 2rem;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: -150px;
  }
`

const Button = styled.button<{ active?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.primary};

  ::after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 47px;
    height: 47px;
    border: 3px solid transparent;
    border-radius: 50%;
  }

  :first-child {
    margin-right: 1.5rem;
    ::after {
      border-top-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  :nth-child(2) {
    ::after {
      border-left-color: ${({ theme }) => theme.colors.secondary};
    }
  }

  ${({ active }) =>
    active &&
    css`
      cursor: pointer;
      border-color: ${({ theme }) => theme.colors.secondary};
      ::after {
        display: block;
      }
    `}
`

const Technologies = styled.div``

const Technology = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

  cursor: pointer;
`

const TechnologyBar = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  margin-bottom: 2rem;
  padding-right: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-left: none;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.primary};
  transition: 0.2s;

  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    max-width: 480px;
  }

  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary};
      border-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.white};
    `}
`

const TechnologyNumber = styled.div<{ active?: boolean }>`
  height: 60px;
  min-width: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'PoppinsSemiBold';
  font-size: 1.3rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white200};
  margin-right: 2rem;
  border-radius: 20px;
  transition: 0.2s;
  ${({ active }) =>
    active &&
    css`
      margin-right: 0;
      background-color: ${({ theme }) => theme.colors.secondary};
      border-color: ${({ theme }) => theme.colors.secondary};
      color: ${({ theme }) => theme.colors.white};
    `}
`

const TechnologyName = styled.div`
  font-size: clamp(1rem, 2.7vw, 1.3rem);
  font-family: 'PoppinsSemiBold';
`

const TechnologyDescription = styled.div`
  font-family: 'PoppinsLight';
  font-size: clamp(1.2rem, 2.2vw, 1.5rem);
  color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    max-width: 550px;
  }
`

const RoiHunter: React.FC<Props> = ({ title, description, technologies }) => {
  const [activeTechIndex, setActiveTechIndex] = useState(0)
  const technologiesGifs = useMemo(
    () =>
      technologies.map(
        ({ mainPageTechnologiesSingleGif }) => mainPageTechnologiesSingleGif
      ),
    [technologies]
  )

  return (
    <StyledSection>
      <Container>
        <StyledHeading dangerouslySetInnerHTML={{ __html: title }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </Container>
      <Content>
        <ImageSection>
          <CirclesIconWrapper>
            <Icon src={circles} />
          </CirclesIconWrapper>
          <LazyLoad offset={600}>
            {technologiesGifs[activeTechIndex] && (
              <GifWrapper>
                <img src={technologiesGifs[activeTechIndex]} alt="gif-table" />
              </GifWrapper>
            )}
          </LazyLoad>
        </ImageSection>
        <Container>
          <TechnologiesSection>
            <Buttons>
              <Button
                active={activeTechIndex > 0}
                onClick={() => setActiveTechIndex((prevIndex) => prevIndex - 1)}
                disabled={activeTechIndex < 1}
              >
                <Icon
                  src={
                    activeTechIndex > 0 ? arrowSecondaryLeft : arrowPrimaryLeft
                  }
                  size={15}
                />
              </Button>
              <Button
                active={activeTechIndex < technologies.length - 1}
                onClick={() => setActiveTechIndex((prevIndex) => prevIndex + 1)}
                disabled={activeTechIndex >= technologies.length - 1}
              >
                <Icon
                  src={
                    activeTechIndex < technologies.length - 1
                      ? arrowSecondaryRight
                      : arrowPrimaryRight
                  }
                  size={15}
                />
              </Button>
            </Buttons>
            <Technologies>
              {technologies.map(
                (
                  {
                    mainPageTechnologiesNameSingle,
                    mainPageTechnologiesDescription,
                  },
                  index
                ) => (
                  <Technology
                    key={mainPageTechnologiesNameSingle}
                    onClick={() => setActiveTechIndex(index)}
                  >
                    <TechnologyBar active={activeTechIndex === index}>
                      <TechnologyNumber active={activeTechIndex === index}>{`0${
                        index + 1
                      }`}</TechnologyNumber>
                      <TechnologyName>
                        {mainPageTechnologiesNameSingle}
                      </TechnologyName>
                    </TechnologyBar>
                    <Collapsible
                      trigger=""
                      open={activeTechIndex === index}
                      transitionTime={325}
                    >
                      <TechnologyDescription>
                        {mainPageTechnologiesDescription}
                      </TechnologyDescription>
                    </Collapsible>
                  </Technology>
                )
              )}
            </Technologies>
          </TechnologiesSection>
        </Container>
      </Content>
    </StyledSection>
  )
}

export default RoiHunter
