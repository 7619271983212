/* eslint-disable */
import React, { useState } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import styled, { css } from 'styled-components'
import Button from 'components/shared/Button'
import Input from './Input'
import Terms from 'components/shared/Terms'
import axios from 'axios'

import * as Yup from 'yup'

type Values = {
  name: string
  phone: string
  email: string
  message: string
  term1: boolean
  term2: boolean
  term3: boolean
}

const StyledForm = styled.div`
  width: 100%;

  & > form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 2rem;

    ${({ theme }) => theme.media.lg.min} {
      margin-top: 4.5rem;
    }
  }
`

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

const WrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${({ theme }) => theme.media.lg.min} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const TermsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
  }
`

const StyledButton = styled(Button)`
  position: relative;
  width: 220px;
  min-width: 220px;
  height: 55px;
  z-index: 5;
  margin: 2rem 0 0;
  ${({ theme }) => theme.media.lg.min} {
    align-self: flex-start;
    margin: 0 0 0 2.5rem;
  }
`

const TermsErrorMessage = styled.p`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;
`

const FormMessage = styled.p<{ error?: boolean }>`
  width: 100%;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #25c925;
  text-align: left;

  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.secondary};
    `};
`

// Email OR phone number is required - only one of them

const MODAL_FORM_SCHEMA = Yup.object().shape(
  {
    name: Yup.string().required(`Proszę o podanie imienia`),
    email: Yup.string().when('phone', {
      // @ts-ignore
      is: (phone) => !phone,
      then: Yup.string()
        .email('Niepoprawny adres email')
        .required('Proszę o podanie adresu email'),
    }),
    phone: Yup.string().when('email', {
      // @ts-ignore
      is: (email) => !email,
      then: Yup.string()
        .min(9, `Numer telefonu jest nieprawidłowy`)
        .matches(
          /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
          `Numer telefonu jest nieprawidłowy`
        )
        .required(`Proszę o podanie numeru telefonu`),
    }),
    term1: Yup.bool().oneOf([true]).required(),
    term2: Yup.bool().oneOf([true]).required(),
    term3: Yup.bool().oneOf([true]).required(),
  },
  // @ts-ignore
  ['email', 'phone']
)

const ModalForm: React.FC = () => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })

  return (
    <Formik
      initialValues={{
        name: '',
        phone: '',
        email: '',
        message: '',
        term1: false,
        term2: false,
        term3: false,
      }}
      validationSchema={MODAL_FORM_SCHEMA}
      onSubmit={(
        values: Values,
        { setSubmitting, resetForm }: FormikHelpers<Values>
      ) => {
        if (formInfo.text && !formInfo.error) return
        ;(async () => {
          try {
            setSubmitting(true)

            const formData = new FormData()

            formData.append('name', values.name)
            formData.append('phone', values.phone || '')
            formData.append('email', values.email || '')
            formData.append('message', values.message)
            formData.append('agree1', values.term1.toString())
            formData.append('agree2', values.term2.toString())
            formData.append('agree3', values.term3.toString())

            const { data } = await axios.post<any>(
              'https://cmspl.b.cz/mailer/mailer.php',
              formData
            )

            console.log(data)

            setSubmitting(false)
            setFormInfo({
              text: 'Dziękujemy za wysłanie formularza!',
              error: false,
            })

            setTimeout(() => {
              setFormInfo({ text: '', error: false })
              resetForm()
            }, 10000)
          } catch (err) {
            console.log(err)
            setSubmitting(false)
            setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            setTimeout(() => {
              setFormInfo({ text: '', error: false })
            }, 5000)
          }
        })()
      }}
    >
      {({ errors, touched, isSubmitting, validateField }) => (
        <StyledForm>
          <Form>
            <InputsWrapper>
              <Field name="name" placeholder="Imię" component={Input} />
              <Field name="email" placeholder="Adres email" component={Input} />
              <Field name="phone" placeholder="Telefon" component={Input} />
              <Field
                name="message"
                placeholder="Napisz kilka słow o swoim projekcie"
                textarea
                component={Input}
              />
            </InputsWrapper>
            {((errors.term1 && touched.term1) ||
              (errors.term2 && touched.term2) ||
              (errors.term3 && touched.term3)) && (
              <TermsErrorMessage>Proszę zaznaczyć zgody</TermsErrorMessage>
            )}
            {formInfo.text && (
              <FormMessage error={formInfo.error}>{formInfo.text}</FormMessage>
            )}
            <WrapperBottom>
              <TermsWrapper>
                <Terms formName="modal" />
              </TermsWrapper>
              <StyledButton
                type="submit"
                isSubmitting={isSubmitting}
                contactForm
              >
                {isSubmitting ? '' : 'Zamawiam'}
              </StyledButton>
            </WrapperBottom>
          </Form>
        </StyledForm>
      )}
    </Formik>
  )
}

export default ModalForm
