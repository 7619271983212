/* eslint-disable */
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

type SeoProps = {
  title?: string
  description?: string
  meta?: JSX.IntrinsicElements['meta'][]
  lang?: string
}

type SiteMetadata = {
  wpPage: { seo: { title: string; metaDesc: string } }
}

const Seo: React.FC<SeoProps> = ({ title, description, lang }) => {
  const {
    wpPage: { seo },
  } = useStaticQuery<SiteMetadata>(
    graphql`
      query {
        wpPage {
          seo {
            title
            metaDesc
          }
        }
      }
    `
  )

  const metaDescription = seo.metaDesc || description
  const metaTitle = seo.title || title

  const defaultMeta = [
    {
      name: 'robots',
      content: 'index, follow',
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: 'google-site-verification',
      content: 'vbwabmG2J1Ln76CsILJh1FGUbGUVl0jXlTT-Cgoabis',
    },
    // {
    //   property: `og:type`,
    //   content: `website`,
    // },
    // {
    //   name: `twitter:card`,
    //   content: `summary`,
    // },
    // {
    //   name: `twitter:title`,
    //   content: title,
    // },
    // {
    //   name: `twitter:description`,
    //   content: metaDescription,
    // },
  ]

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      meta={defaultMeta}
      script={[
        {
          type: 'text/javascript',
          innerHTML: `var _mtm = window._mtm = window._mtm || [];
                      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                      g.async=true; g.src='https://www.googletagmanager.com/gtag/js?id=G-7V4NXBW5CC'; s.parentNode.insertBefore(g,s);`,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', 'G-7V4NXBW5CC');`,
        },
        {
          type: 'text/javascript',
          innerHTML: `var _mtm = window._mtm = window._mtm || [];
                      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                      g.async=true; g.src='https://www.googletagmanager.com/gtag/js?id=UA-222471440-1'; s.parentNode.insertBefore(g,s);`,
        },
        {
          type: 'text/javascript',
          innerHTML: `window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                      gtag('config', 'UA-222471440-1');`,
        },
      ]}
    />
  )
}

export default Seo
