/* eslint-disable */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import Cookie from 'js-cookie'
import Button from 'components/shared/Button'

const CookiesWrapper = styled.aside`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ebecef;
  opacity: 1;
  transition: 0.3s ease;
  z-index: 1000000;
  ${({ hidden }) =>
    hidden &&
    css`
      opacity: 0;
      transform: translateY(100px);
    `}
`

const CookiesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  width: 260px;
  padding-top: 1.5rem;

  ${({ theme }) => theme.media.sm.min} {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 2rem;
  }
`

const CookiesText = styled.p`
  font-family: 'PoppinsMedium';
  font-size: 1.3rem;
  line-height: normal;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
`

const CookiesButton = styled(Button)`
  width: 260px;
  min-width: 260px;
  margin: 1.75rem 0;

  ${({ theme }) => theme.media.sm.min} {
    margin-top: 0;
    margin-left: 2.5rem;
    width: 120px;
    min-width: 120px;
  }

  ${({ theme }) => theme.media.xl.min} {
    margin-top: 0;
    margin-left: 3.5rem;
  }
`

const COOKIE_KEY = 'bfactory-cookies-consent'

const Cookies = () => {
  const [visible, setVisible] = useState(false)
  const [removeAlert, setRemoveAlert] = useState(false)

  const acceptCookies = () => {
    Cookie.set(COOKIE_KEY, 'true')
    setVisible(false)
    setTimeout(() => setRemoveAlert(true), 500)

    localStorage.setItem(COOKIE_KEY, 'true')
  }

  useEffect(() => {
    const value = localStorage.getItem(COOKIE_KEY)
    if (value) {
      setVisible(false)
      setTimeout(() => setRemoveAlert(true), 500)
    } else {
      setVisible(true)
    }
  }, [])

  return (
    <>
      {!removeAlert && (
        <CookiesWrapper hidden={!visible}>
          <CookiesContainer>
            <CookiesText>
              Strona korzysta z plików cookies w celu realizacji usług. Możesz
              określić warunki przechowywania lub dostępu do plików cookies w
              Twojej przeglądarce.
            </CookiesText>

            <CookiesButton onClick={acceptCookies}>OK</CookiesButton>
          </CookiesContainer>
        </CookiesWrapper>
      )}
    </>
  )
}

export default Cookies
