import React from 'react'
import styled, { css, keyframes } from 'styled-components'

type Props = {
  type?: 'button' | 'submit' | 'reset' | undefined
  className?: string
  children: string
  onClick?: () => void
  isSubmitting?: boolean
  contactForm?: boolean
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const CustomButton = styled.button<{
  submitting?: boolean
  contactForm?: boolean
}>`
  position: relative;
  height: 55px;
  margin-top: 3rem;
  font-size: 1.5rem;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  cursor: pointer;

  ${({ theme }) => theme.media.md.min} {
    height: 60px;
  }

  ${({ theme }) => theme.media.lg.min} {
    font-size: 1.6rem;
  }

  ${({ contactForm }) =>
    contactForm &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid ${({ theme }) => theme.colors.white};
      }
    `}

  ${({ submitting }) =>
    submitting &&
    css`
      ::after {
        content: '';
        position: absolute;
        top: calc(50% - 11px);
        left: calc(50% - 10px);
        width: 20px;
        height: 20px;
        border: 3px solid ${({ theme }) => theme.colors.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        background: none;
        opacity: (submitting ? '1': '0');
        animation: ${spin} 1s ease infinite;
        z-index: 5;
      }
    `}
`

const Button: React.FC<Props> = ({
  type,
  className,
  children,
  isSubmitting,
  onClick,
  contactForm,
}) => {
  return (
    <CustomButton
      type={type}
      className={className}
      submitting={isSubmitting}
      onClick={onClick}
      contactForm={contactForm}
    >
      {isSubmitting ? '' : children}
    </CustomButton>
  )
}

export default Button
