import React from 'react'
import styled, { css } from 'styled-components'
import { FieldProps } from 'formik'

type Props = {
  placeholder?: string
  required?: boolean
  disabled?: boolean
  readonly?: boolean
  textarea?: boolean
}

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 2rem;
  ${({ theme }) => theme.media.lg.min} {
    width: 30%;
    margin-bottom: 3rem;
  }
`

const Input = styled.input<{ error: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 45px;
  padding: 0 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};

  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ error }) =>
    error &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.secondary};
    `}
`

const Textarea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  height: 140px;
  padding: 1rem 1.5rem;
  margin-bottom: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  color: ${({ theme }) => theme.colors.primary};
  ::placeholder {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 100%;
    margin-bottom: 3rem;
  }
`

const ErrorMessage = styled.p`
  margin-top: -0.75rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: left;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: -25px;
    left: 0;
    margin: 0;
  }
`

const FormInput: React.FC<Props & FieldProps> = ({
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  required,
  placeholder,
  disabled,
  readonly,
  textarea,
}) => {
  const errorText = errors[name] && touched[name] && errors[name]

  return textarea ? (
    <Textarea
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      disabled={disabled}
      readOnly={!!readonly}
      required={required}
    />
  ) : (
    <InputWrapper>
      {!!errorText && <ErrorMessage>{errorText}</ErrorMessage>}
      <Input
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        readOnly={!!readonly}
        required={required}
        error={!!errorText}
      />
    </InputWrapper>
  )
}

export default FormInput
