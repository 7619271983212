import React from 'react'
import styled from 'styled-components'
import p1 from 'assets/icons/pinterestp1.svg'
import p2 from 'assets/icons/pinterestp2.svg'
import Icon from './Icon'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  & > :first-child {
    margin-right: 0.5rem;
  }
`

const PinterestIcon = () => {
  return (
    <Wrapper>
      <Icon src={p1} />
      <Icon src={p2} />
    </Wrapper>
  )
}

export default PinterestIcon
