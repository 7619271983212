import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import Icon from 'components/shared/Icon'
import closeIcon from 'assets/icons/close.svg'
import useOutsideClick from 'hooks/useOutsideClick'
import ModalForm from './ModalForm'

type Props = {
  visible: boolean
  close: () => void
}

const Wrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6rem 2rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  visibility: hidden;
  z-index: 1000;

  ${({ visible }) =>
    visible &&
    css`
      visibility: visible;
    `}

  ${({ theme }) => theme.media.sm.min} {
    padding: 6rem 3.5rem 2rem;
  }

  ${({ theme }) => theme.media.md.min} {
    padding: 6rem 5rem 3rem;
  }

  ${({ theme }) => theme.media.lg.min} {
    top: 50%;
  }
`

const Title = styled.h4`
  width: 100%;
  font-size: clamp(1.7rem, 3.2vw, 3rem);
  color: ${({ theme }) => theme.colors.white};
  text-align: left;
  margin-bottom: 1rem;
`

const Subtitle = styled.h5`
  width: 100%;
  font-size: clamp(1.2rem, 2.6vw, 1.5rem);
  color: ${({ theme }) => theme.colors.white100};
  text-align: left;
`

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;

  ${({ theme }) => theme.media.sm.min} {
    top: 25px;
    right: 35px;
  }

  ${({ theme }) => theme.media.md.min} {
    right: 50px;
  }

  ${({ theme }) => theme.media.lg.min} {
    top: 25px;
    right: 25px;
  }
`

const Modal: React.FC<Props> = ({ visible, close }) => {
  const wrapperRef = useRef(null)

  useOutsideClick({
    ref: wrapperRef,
    handler: close,
  })

  return (
    <Wrapper visible={visible} ref={wrapperRef}>
      <Title>
        Umów się na darmowy audyt <br /> konta reklamowego
      </Title>
      <Subtitle>
        Odezwiemy się do ciebie i zaproponujemy dogodny termin na konsultację
      </Subtitle>
      <ModalForm />
      <CloseIconWrapper onClick={close}>
        <Icon src={closeIcon} size={28} />
      </CloseIconWrapper>
    </Wrapper>
  )
}

export default Modal
