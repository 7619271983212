/* eslint-disable */
import * as React from 'react'
import styled from 'styled-components'
import { PageProps, graphql } from 'gatsby'
import MainLayout from 'components/layout/MainLayout'
import Header from 'components/layout/Header'
import MarketingPartner from 'components/layout/MarketingPartner'
import RoiHunter from 'components/layout/RoiHunter'
import Footer from 'components/layout/Footer'
import Contact from 'components/layout/Contact'
import Appointment from 'components/layout/Appointment'
import Cookies from 'components/shared/Cookies'
import SEO from 'components/shared/SEO'
import '../styles/main.css'

type DataProps = {
  data: {
    wpPage: {
      MainPage: any
    }
  }
}

const Main = styled.main`
  background-color: ${({ theme }) => theme.colors.primary};
`

const IndexPage: React.FC<PageProps<DataProps>> = ({ data }) => {
  const HOMEPAGE = data.wpPage.MainPage

  return (
    <MainLayout>
      <SEO />
      <Cookies />
      <Header
        title={HOMEPAGE.mainPageHeroTitle}
        subtitle={HOMEPAGE.mainPageHeroSubtitle}
        clouds={HOMEPAGE.mainPageHeroCommunions}
      />
      <Main>
        <MarketingPartner
          title={HOMEPAGE.mainPageServicesTitle}
          subtitle={HOMEPAGE.mainPageServicesSubtitle}
          description={HOMEPAGE.mainPageServicesDescription}
          services={HOMEPAGE.mainPageServicesService}
        />
        <RoiHunter
          title={HOMEPAGE.mainPageTechnologyTitle}
          description={HOMEPAGE.mainPageTechnologyDescription}
          technologies={
            HOMEPAGE.mainPageTechnologies[0].mainPageTechnologiesSingle
          }
        />
        <Contact title={HOMEPAGE.mainPageContactTitle} />
        <Appointment
          title={HOMEPAGE.mainPageSeparatorTitle}
          img={data.appointmentBackground}
        />
      </Main>
      <Footer />
    </MainLayout>
  )
}

export default IndexPage

export const query = graphql`
  query queryHomePage {
    appointmentBackground: file(name: { eq: "legacy-tower-warsaw" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [WEBP])
      }
    }
    wpPage {
      MainPage {
        mainPageHeroTitle
        mainPageHeroSubtitle
        mainPageHeroCommunions {
          mainPageHeroCommunionSingle
          mainPageHeroCommunionsIcon
        }
        mainPageServicesTitle
        mainPageServicesSubtitle
        mainPageServicesDescription
        mainPageServicesService {
          mainPageServicesServiceDescription
          mainPageServicesServiceIcon
          mainPageServicesServiceTitle
        }
        mainPageTechnologyTitle
        mainPageTechnologyDescription
        mainPageTechnologies {
          mainPageTechnologiesSingle {
            mainPageTechnologiesDescription
            mainPageTechnologiesNameSingle
            mainPageTechnologiesSingleGif
          }
        }
        mainPageContactTitle
        mainPageSeparatorTitle
      }
    }
  }
`
