import React from 'react'
import styled from 'styled-components'
import { CloudObj } from 'types/data'
import Container from 'components/shared/Container'
import Icon from 'components/shared/Icon'
import bfactoryLogo from 'assets/icons/bfactory-logo.svg'
import PinterestIcon from 'components/shared/PinterestIcon'
import Button from 'components/shared/Button'
import meta from 'assets/icons/meta.svg'
import google from 'assets/icons/google-partner.svg'
import tikTok from 'assets/icons/tiktok.svg'
import womanBackground from 'assets/images/woman.png'
import diamond from 'assets/icons/diamond.svg'
import star from 'assets/icons/star.svg'
import chart from 'assets/icons/chart.svg'
import payment from 'assets/icons/payment.svg'
import scrollToSection from 'utils/scrollToSection'

type Props = {
  title: string
  subtitle: string
  clouds: CloudObj[]
}

const StyledHeader = styled.header`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.media.lg.min} {
    background-image: url(${womanBackground});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: calc(32vw + 17%);
  }

  ${({ theme }) => theme.media.xl.min} {
    background-position-x: calc(32vw + 23%);
  }
`

const StyledContainer = styled(Container)`
  max-width: 1050px;
  padding-bottom: 4.5rem;
  ${({ theme }) => theme.media.lg.min} {
    padding-bottom: 15rem;
  }
`

const WrapperTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  margin-bottom: 3rem;
  ${({ theme }) => theme.media.lg.min} {
    height: 90px;
    margin-bottom: 10rem;
  }
`

const CompanyIconWrapper = styled.div`
  position: relative;
  width: 50%;
  max-width: 160px;
  z-index: 1;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 180px;
  }
`

const CompanyIconBackground = styled.div`
  position: absolute;
  top: -30px;
  left: 0;
  width: calc(100% + 50px);
  height: 70px;
  transform: translateX(-25px);
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom-right-radius: 70px;
  border-bottom-left-radius: 70px;
  z-index: -1;

  ${({ theme }) => theme.media.lg.min} {
    top: -75px;
    left: 0;
    width: calc(100% + 180px);
    height: 125px;
    transform: translateX(-90px);
    background-color: #ffffff;
    border-bottom-right-radius: 110px;
    border-bottom-left-radius: 110px;
  }
`

const Link = styled.a`
  position: relative;
  font-family: 'LatoBold';
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.white};
  padding: 1rem 2.25rem;
  text-transform: uppercase;
  z-index: 3;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`

const ContentWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    width: 58%;
    margin-right: auto;
  }
`

const Title = styled.h1`
  margin-bottom: 1rem;
  font-weight: 300;
  font-size: clamp(2.2rem, 3vw, 2.5rem);
  color: ${({ theme }) => theme.colors.white100};
  line-height: 1.3;

  & > br:nth-child(2) {
    display: none;
  }

  ${({ theme }) => theme.media.lg.min} {
    font-family: 'PoppinsLight';
    font-size: clamp(2.7rem, 1vw, 4.5rem);
    color: ${({ theme }) => theme.colors.white};

    & > br:nth-child(1) {
      display: none;
    }

    & > br:nth-child(2) {
      display: block;
    }
  }
`

const Subtitle = styled.h6`
  font-size: clamp(1.3rem, 1.8vw, 1.6rem);
  color: ${({ theme }) => theme.colors.white100};

  ${({ theme }) => theme.media.lg.min} {
    font-size: clamp(1.4rem, 1vw, 1.8rem);
  }
`

const Logos = styled.div`
  width: 100%;
  max-width: 330px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  margin: 7rem auto 0;

  ${({ theme }) => theme.media.sm.min} {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    max-width: unset;
    padding: 0 2rem;
  }

  ${({ theme }) => theme.media.lg.min} {
    justify-content: flex-start;
    padding-left: 0;
  }
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-self: center;
  width: 75%;
  &:not(:last-child) > div {
    align-items: flex-start;
  }

  ${({ theme }) => theme.media.lg.min} {
    width: 16%;
  }
`

const Clouds = styled.div`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  ${({ theme }) => theme.media.lg.min} {
    display: block;
  }
`

const Cloud = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 30px;

  // Diamond cloud
  &:nth-child(1) {
    top: 14%;
    right: -10%;
    padding: 0.75rem 2rem 0.75rem 1.5rem;
    // IconWrapper inside Diamond cloud
    & > :first-child {
      width: 30px;
      height: 30px;
    }

    ${({ theme }) => theme.media.xxl.min} {
      top: 18%;
      right: -14%;
    }
  }

  // Star cloud
  &:nth-child(2) {
    top: 23%;
    right: calc(28% + 25px);
    padding: 0.75rem 2rem 0.75rem 0.75rem;

    ${({ theme }) => theme.media.xxl.min} {
      top: 29%;
      right: 28%;
    }
  }

  // Chart cloud
  &:nth-child(3) {
    top: 52%;
    right: calc(-16% + 50px);
    flex-direction: row-reverse;
    border-radius: 15px;
    padding: 0.75rem 1.5rem;
    // IconWrapper inside Chart cloud
    & > :first-child {
      margin-right: 0;
      margin-left: 1.5rem;
    }

    ${({ theme }) => theme.media.xl.min} {
      right: calc(-16% + 60px);
    }
    ${({ theme }) => theme.media.xxl.min} {
      right: -7.5%;
    }
  }

  // Payment cloud
  &:nth-child(4) {
    top: 67%;
    right: 21%;
    padding: 0.75rem 2rem 0.75rem 1rem;
    // IconWrapper inside Payment cloud
    & > :first-child {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #4ed18a;
      padding: 0.5rem;
    }

    ${({ theme }) => theme.media.xl.min} {
      right: 25%;
    }
  }
`

const CloudIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
`

const CloudText = styled.div`
  font-family: 'LatoBold';
  font-size: 1.1rem;
`

const StyledButton = styled(Button)`
  position: relative;
  z-index: 10000;
  width: 100%;

  ${({ theme }) => theme.media.lg.min} {
    max-width: 330px;
    height: 45px;
  }
`

const getIcon = (icon: string) => {
  switch (icon.toLowerCase()) {
    case 'diament':
      return diamond
    case 'gwiazdka':
      return star
    case 'wykres':
      return chart
    case 'wydatki':
      return payment
    default:
      return ''
  }
}

const Header: React.FC<Props> = ({ title, subtitle, clouds }) => {
  return (
    <StyledHeader>
      <StyledContainer>
        <WrapperTop>
          <CompanyIconWrapper>
            <CompanyIconBackground />
            <a href="/">
              <Icon src={bfactoryLogo} />
            </a>
          </CompanyIconWrapper>
          <Link href="https://b.cz/">en</Link>
        </WrapperTop>
        <ContentWrapper>
          <Title dangerouslySetInnerHTML={{ __html: title }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
          <StyledButton onClick={() => scrollToSection('#marketing-partner')}>
            Chcę poprawić swoje wyniki
          </StyledButton>
          <Logos>
            <LogoWrapper>
              <Icon src={meta} />
            </LogoWrapper>
            <LogoWrapper>
              <Icon src={google} />
            </LogoWrapper>
            <LogoWrapper>
              <Icon src={tikTok} />
            </LogoWrapper>
            <LogoWrapper>
              <PinterestIcon />
            </LogoWrapper>
          </Logos>
          <Clouds>
            {clouds.map(
              ({ mainPageHeroCommunionSingle, mainPageHeroCommunionsIcon }) => (
                <Cloud key={mainPageHeroCommunionSingle}>
                  <CloudIconWrapper>
                    <Icon src={getIcon(mainPageHeroCommunionsIcon)} size={40} />
                  </CloudIconWrapper>
                  <CloudText
                    dangerouslySetInnerHTML={{
                      __html: mainPageHeroCommunionSingle,
                    }}
                  />
                </Cloud>
              )
            )}
          </Clouds>
        </ContentWrapper>
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header
