/* eslint-disable */
import React, { useState } from 'react'
import { Formik, Field, Form, FormikHelpers } from 'formik'
import styled, { css } from 'styled-components'
import Input from 'components/shared/Input'
import Button from 'components/shared/Button'
import Terms from 'components/shared/Terms'
import axios from 'axios'

import inputWorldIcon from 'assets/icons/world.svg'
import inputMessageIcon from 'assets/icons/message.svg'

import * as Yup from 'yup'
import { websiteRE } from 'utils/websiteRegex'

type Values = {
  email: string
  site: string
  message: string
  agree1: boolean
  agree2: boolean
  agree3: boolean
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 5rem 0 3rem;
  z-index: 3;
  ${({ theme }) => theme.media.lg.min} {
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-left: clamp(90px, 10vw, 150px);
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ theme }) => theme.media.lg.min} {
      max-width: 360px;
    }
  }
`

const FormMessage = styled.p<{ error?: boolean }>`
  align-self: flex-start;
  margin-top: 2.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #25c925;
  ${({ error }) =>
    error &&
    css`
      color: ${({ theme }) => theme.colors.secondary};
    `};
`

const WrapperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const TermsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;

  ${({ theme }) => theme.media.lg.min} {
    height: 125px;
    overflow-y: auto;

    ::-webkit-scrollbar {
      border-radius: 5px;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 5px;
      background: #ffffff;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.secondary};
      border-radius: 5px;
      border: 2px solid transparent;
    }
  }
`

const TermsErrorMessage = styled.p`
  align-self: flex-start;
  margin-top: 2.5rem;
  font-size: 1.3rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondary};
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 360px;

  ${({ theme }) => theme.media.md.min} {
    width: 220px;
    align-self: flex-end;
  }
`

const CONTACT_FORM_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .email(`Adres email jest nieprawidłowy`)
    .required(`Proszę podać adres e-mail`),
  site: Yup.string().matches(websiteRE, 'Adres jest nieprawidłowy'),
  message: Yup.string()
    .min(10, 'Wiadomość musi składać się z 10 znaków')
    .required('Proszę napisać wiadomość'),
  agree1: Yup.bool().oneOf([true]).required('Proszę zaznaczyć zgody'),
  agree2: Yup.bool().oneOf([true]).required('Proszę zaznaczyć zgody'),
  agree3: Yup.bool().oneOf([true]).required('Proszę zaznaczyć zgody'),
})

const ContactForm: React.FC = () => {
  const [formInfo, setFormInfo] = useState({
    text: '',
    error: false,
  })

  return (
    <Formik
      initialValues={{
        email: '',
        site: '',
        message: '',
        agree1: false,
        agree2: false,
        agree3: false,
      }}
      validationSchema={CONTACT_FORM_SCHEMA}
      onSubmit={(
        values: Values,
        { setSubmitting, resetForm }: FormikHelpers<Values>
      ) => {
        if (formInfo.text && !formInfo.error) return
        ;(async () => {
          try {
            setSubmitting(true)

            const formData = new FormData()

            formData.append('email', values.email)
            formData.append('site', values.site || '')
            formData.append('message', values.message)
            formData.append('agree1', values.agree1.toString())
            formData.append('agree2', values.agree2.toString())
            formData.append('agree3', values.agree3.toString())

            const { data } = await axios.post<any>(
              'https://cmspl.b.cz/consultation-contact-form/form.php',
              formData
            )

            setSubmitting(false)
            setFormInfo({
              text: 'Dziękujemy za wysłanie formularza!',
              error: false,
            })

            setTimeout(() => {
              setFormInfo({ text: '', error: false })
              resetForm()
            }, 10000)
          } catch (err) {
            console.log(err)
            setSubmitting(false)
            setFormInfo({ text: 'Coś poszło nie tak!', error: true })
            setTimeout(() => {
              setFormInfo({ text: '', error: false })
            }, 5000)
          }
        })()
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Wrapper>
          <Form>
            <Field
              name="email"
              icon={inputMessageIcon}
              placeholder="Twój adres email"
              component={Input}
              contactForm
            />
            <Field
              name="site"
              icon={inputWorldIcon}
              placeholder="https://mojastrona.pl"
              component={Input}
              contactForm
              websiteInput
            />
            <Field
              name="message"
              icon={inputMessageIcon}
              placeholder="Napisz czego potrzebujesz"
              component={Input}
              textarea
              contactForm
            />
            <WrapperBottom>
              {((errors.agree1 && touched.agree1) ||
                (errors.agree2 && touched.agree2) ||
                (errors.agree3 && touched.agree3)) && (
                <TermsErrorMessage>Proszę zaznaczyć zgody</TermsErrorMessage>
              )}
              {formInfo.text && (
                <FormMessage error={formInfo.error}>
                  {formInfo.text}
                </FormMessage>
              )}
              <TermsWrapper>
                <Terms formName="contact" />
              </TermsWrapper>
              <StyledButton type="submit" isSubmitting={isSubmitting}>
                {isSubmitting ? '' : 'Wyślij'}
              </StyledButton>
            </WrapperBottom>
          </Form>
        </Wrapper>
      )}
    </Formik>
  )
}

export default ContactForm
